import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutenticacaoGuard, LoggedInGuard, PartnerResolver } from '@bs2/utilities-v9';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./modules/autenticado/autenticado.module').then((m) => m.AutenticadoModule),
		canActivate: [AutenticacaoGuard],
		resolve: { partner: PartnerResolver },
	},
	{
		path: 'autenticacao',
		loadChildren: () => import('./modules/autenticacao/autenticacao.module').then((m) => m.AutenticacaoModule),
		canActivate: [LoggedInGuard],
		resolve: { partner: PartnerResolver },
	},
	{
		path: 'recuperar-senha',
		loadChildren: () => import('./modules/recuperar-senha/recuperar-senha.module').then((m) => m.RecuperarSenhaModule),
		canActivate: [LoggedInGuard],
		resolve: { partner: PartnerResolver },
	},
	{
		path: 'remover-dispositivo',
		loadChildren: () => import('./modules/remover-dispositivo/remover-dispositivo.module').then((m) => m.RemoverDispositivoModule),
		canActivate: [LoggedInGuard],
		resolve: { partner: PartnerResolver },
	},
	{
		path: 'pendencias',
		loadChildren: () => import('./modules/pendencias/pendencias.module').then((m) => m.PendenciasModule),
		resolve: { partner: PartnerResolver },
	},
	{
		path: 'primeiro-acesso',
		loadChildren: () => import('./modules/primeiro-acesso/primeiro-acesso.module').then((m) => m.PrimeiroAcessoModule),
		canActivate: [LoggedInGuard],
		resolve: { partner: PartnerResolver },
	},
	{
		path: 'blank',
		children: [],
	},
	{ path: '**', redirectTo: '' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule],
})
export class AppRoutingModule { }
