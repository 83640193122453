import { Component, OnInit } from '@angular/core';
import { Bs2AutenticacaoService, Bs2GTAGServiceService, Bs2PageProgressBarService, WhiteLabelService } from '@bs2/utilities-v9';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event } from '@angular/router';

declare const gtag;
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	title = 'bs2-pj-empresas-uis-web';

  public welcomeMessage = $localize`:@@welcomeMessage:Bem-vindo ao nosso site!`;


	constructor(
		private router: Router,
		private pageProgressBarService: Bs2PageProgressBarService,
		private autenticacaoService: Bs2AutenticacaoService,
		private gtagService: Bs2GTAGServiceService
	) {
		this.router.events.subscribe((event: Event) => {
			switch (true) {
				case event instanceof NavigationStart: {
					this.pageProgressBarService.show();

					if (typeof gtag !== 'undefined') {
						gtag('config', ' UA-182899776-1 ', { groups: 'wac_hmg' });
						gtag('config', ' G-6G4GEHSHSQ ', { groups: 'wac_hmg' });
					}

					break;
				}

				case event instanceof NavigationEnd:
				case event instanceof NavigationCancel:
				case event instanceof NavigationError: {
					this.pageProgressBarService.hide();
					break;
				}
				default: {
					break;
				}
			}
		});
	}

	ngOnInit() {
		const documento =
			this.autenticacaoService.empresas &&
			this.autenticacaoService.empresas.length > 0 &&
			this.autenticacaoService.activeEmpresa.documento;

		if (documento) {
			this.gtagService.setHashUserID(documento);
		}
	}
}
