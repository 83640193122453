import { Injectable } from '@angular/core';
import { Bs2AutenticacaoService, Bs2RestClientService } from '@bs2/utilities-v9';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ParametrosModel } from './model/Parametros.model';
import { TimeModel } from './model/Time.model';
import moment from 'moment/moment';
import { Observable } from 'rxjs';
import { EmpresaModel } from './model/Empresa.model';
import { RetornoSessaoIdQrCode } from 'src/app/core/models/retorno-sessao-id-qr-code.model';
import { RetornoStatusQrCode } from '../../models/retorno-status-qr-code.model';
import { of } from "rxjs";

@Injectable({
	providedIn: 'root',
})
export class EmpresasService {

	constructor(private autenticacaoService: Bs2AutenticacaoService, private restClientService: Bs2RestClientService) {}

	dadosEmpresa(): Observable<EmpresaModel> {
		return this.restClientService.get<EmpresaModel>(`${environment.baseURL}/empresas/atualizacao-cadastral`, {
			jweFull: true,
		});
	}

	getTermoMigracaoContaPagamentoPendente(): Observable<{
		pendente: boolean;
	}> {
		const empresaId = this.autenticacaoService.activeEmpresa.id;

		return this.restClientService.get<{
			pendente: boolean;
		}>(`${environment.baseURL}/empresas/${empresaId}/migracoes/contas/pendencia`, {
			hideErrorToast: true,
		});
	}

	obterSessaoIdQrCode(mobileToken: string): Observable<RetornoSessaoIdQrCode> {

		return this.restClientService.post(
			`${environment.baseURLQrCode}/logins/qrcodes`,
			{
				body: {mobileToken: mobileToken},
				sendfingerPrintData: true,
				jweFull: true,
			}
		);
	}

	iniciarSessaoQrCode(hash: string, qrCodeId: string) {

		return this.restClientService.patch(
			`${environment.baseURLQrCode}/logins/qrcodes/${qrCodeId}`,
			{
				body: {hash: hash},
				sendfingerPrintData: true,
				jweFull: true
			}
		);
	}

	consultaStatusQrCode(qrCodeId: string): Observable<RetornoStatusQrCode> {

		return this.restClientService.get(
			`${environment.baseURLQrCode}/logins/qrcodes/${qrCodeId}/status`,
			{
				sendfingerPrintData: true,
				hideErrorToast: true,
				jweFull: true
			}
		);
	}
}
